<template>
  <div class="page">
    <el-row :gutter="24">
      <el-col :span="5">
        <el-card>
          <el-tree
              :data="categoriesList"
              :props="defaultProps"
              node-key="id"
              @node-drop="nodeDrop"
              @node-click="nodeClick"
              :render-content="renderContent"
              draggable>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="10" :offset="1">
        <el-form label-position="left" label-width="120px" :rules="fromRule" :model="form" ref="form">
          <h2>基本信息</h2>
          <el-form-item label="分类名" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="上级分类">
            <el-cascader
                v-model="form.parent_id"
                :options="categoriesList"
                :show-all-levels="false"
                :props="{ expandTrigger: 'hover',checkStrictly: true,value:'id',label:'name',children:'subclass' }"
            />
            <span style="display: block">tips:不选择分类默认为顶级分类</span>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="form.is_show" active-value="1" inactive-value="0"></el-switch>
          </el-form-item>
          <el-form-item label="小图标">
            <div class="picItem" @click="showSelecter=true;selectType=1">
              <el-image v-if="form.min_icon" :src="form.min_icon" style="width: 100px; height: 100px;border-radius: 10px"/>
              <el-button icon="el-icon-plus" plain style="width: 100px; height: 100px;border-radius: 10px"/>
            </div>
          </el-form-item>
          <el-form-item label="分类图标">
            <div class="picItem" @click="showSelecter=true;selectType=2">
              <el-image v-if="form.centre_icon" :src="form.centre_icon" style="width: 100px; height: 100px;border-radius: 10px"/>
              <el-button icon="el-icon-plus" plain style="width: 100px; height: 100px;border-radius: 10px"/>
            </div>
          </el-form-item>
          <el-form-item label="菜单图标">
            <div class="picItem" @click="showSelecter=true;selectType=3">
              <el-image v-if="form.max_icon" :src="form.max_icon" style="width: 100px; height: 100px;border-radius: 10px"/>
              <el-button icon="el-icon-plus" plain style="width: 100px; height: 100px;border-radius: 10px"/>
            </div>
          </el-form-item>
          <h2>关联商品属性</h2>
          <el-form-item label="属性">
            <el-select v-model="form.attribute_ids"></el-select>
          </el-form-item>
        </el-form>
        <div class="fucView">
          <el-button @click="reSet('form')">
            重置
          </el-button>
          <el-button type="primary" @click="handelConfirm('form')">
            保存
          </el-button>
        </div>
      </el-col>
    </el-row>
    <fileSelect :visible.sync="showSelecter" title="选择商品图片" state="pic" @confirm="showSelecterSubmit"></fileSelect>
  </div>
</template>

<script>
import fileSelect  from "@/views/components/fileSelect";
import {apiGetCategoryList,apiAddOrUpdateCategory,apiDelCategory} from "@/request/api";
export default {
  name: "goodsCategories",
  components: {
    fileSelect
  },
  watch: {
    categoriesList: function (val) {
      console.log(val);
    }
  },
  data() {
    return {
      showSelecter:false,
      selectType:null,
      categoriesList: [],
      defaultProps: {
        children: 'subclass',
        label: 'name'
      },
      form: {
        name: '',
        parent_id: '',
        is_show: '1',
        min_icon: '',
        centre_icon: '',
        max_icon: '',
        attribute_ids: ''
      },
      fromRule: {
        name: [
          {required: true, message: '请输入分类名', trigger: 'blur'}
        ],
      }
    };
  },
  created() {
    this.getCategoryList();
  },
  methods: {
      renderContent(h, { node, data, store }) {
          return (
              <span class="custom-tree-node">
                <span>{node.label}</span>
                <span>
                  <el-button size="mini" type="text" on-click={ () => this.remove(node, data) }>Delete</el-button>
                </span>
              </span>);
      },
      remove(node, data) {
          let that = this
          that.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          }).then(() => {
              var id = data.id
              apiDelCategory({id}).then(res => {
                  if(res.code == 200){
                      that.getCategoryList()
                      that.$message.success(res.message)
                  }else{
                      that.$message.error(res.message)
                  }
              })
          })

      },
    getCategoryList() {
      apiGetCategoryList().then(res => {
        if(res.code === 200) {
          this.categoriesList = res.data;
        }
      });
    },
    // allowDrop(Node,dropNode,inner,event){
    //   if(dropNode.level == 3){
    //     return false;
    //   }
    //   return true
    // },
    nodeDrop(Node,dropNode,inner,event){
      var id = Node.data.id;
      var parent_id = null
      if(inner == 'inner'){
        parent_id = dropNode.data.id;
      }
      if(inner == 'after'){
        parent_id = dropNode.data.parent_id;
      }
      if(inner == 'before'){
        parent_id = dropNode.data.parent_id;
      }
      apiAddOrUpdateCategory({id,parent_id}).then(res => {
        this.getCategoryList()
      });
    },
    nodeClick(e){
        console.log(e)
        this.form = {
            name: e.name,
            parent_id: e.parent_id,
            is_show: e.is_show,
            min_icon: e.min_icon,
            centre_icon: e.centre_iocn,
            max_icon: e.max_iocn,
            attribute_ids: e.attribute_ids,
            id: e.id
        }
    },
    showSelecterSubmit(data) {
      if(this.selectType == 1) {
        this.form.min_icon = data.url;
      } else if(this.selectType == 2) {
        this.form.centre_icon = data.url;
      } else if(this.selectType == 3) {
        this.form.max_icon = data.url;
      }
    },
    reSet(formName){
      this.$refs[formName].resetFields();
    },
    handelConfirm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiAddOrUpdateCategory(this.form).then(res => {
            if(res.code == 200){
                this.$message.success(res.message)
                this.getCategoryList()
                this.$refs[formName].resetFields();
            }else{
                this.$message.error(res.message)
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

  }
}
</script>

<style lang="less">
.page {
  background-color: #FFF;
  padding: 20px;
  //margin: 20px;
}
.el-form-item {
  margin-bottom: 10px;
}
.picItem {
  width: 100px;
  height: 100px;
  margin: 5px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.picItem:hover {
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.25);
}
.fucView {
  display: flex;
  justify-content: flex-end;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
